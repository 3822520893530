import { render, staticRenderFns } from "./supplement.vue?vue&type=template&id=2851b67a&scoped=true&"
import script from "./supplement.vue?vue&type=script&lang=js&"
export * from "./supplement.vue?vue&type=script&lang=js&"
import style0 from "./supplement.vue?vue&type=style&index=0&id=2851b67a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2851b67a",
  null
  
)

export default component.exports