<template>
  <div class="materials">
    <div class="title">
      <div class="tit_info">
        <span>
          办公房屋租赁合同:
          <i style="color:red">*</i>
        </span>
        办公室的房屋租赁合同，可添加多张照片
      </div>
      <Upload-item @handleChange="(val) => importFile(val, 6)" :fileList="officeLeaseContractUrlList" @ondelete="(i) => ondelete(i, 6)"></Upload-item>
    </div>

    <div class="title">
      <div class="tit_info">
        <span>
          经营场地:
          <i style="color:red">*</i>
        </span>
        场地办公照片(含门头照)，办公场地定位截图，办公场所视频，可添加多张照片
      </div>
      <Upload-item @handleChange="(val) => importFile(val, 4)" :fileList="premisesUrlList" @ondelete="(i) => ondelete(i, 4)"></Upload-item>
    </div>

    <div class="title">
      <div class="tit_info">
        <span>缴费证明:</span>
        物业费、水费、电费、租金缴纳证明(缴费打款凭证、收据、发票，能清晰看到付款方和收款方)
      </div>
      <Upload-item @handleChange="(val) => importFile(val, 5)" :fileList="paymentCertificateUrlList" @ondelete="(i) => ondelete(i, 5)"></Upload-item>
    </div>

    <div class="title">
      <div class="tit_info">
        <span>社保缴纳证明:</span>
        近期连续3个月员工(或个人)社保缴纳记录(不接受Excel版本，需要社保局拉取或者社保网站截图，能清晰看到企业名称、个人姓名和社保缴纳总人数)，简版和完整版都可
      </div>
      <Upload-item @handleChange="(val) => importFile(val, 2)" :fileList="socialInsurancePaymentCertificateUrlList" @ondelete="(i) => ondelete(i, 2)"></Upload-item>
    </div>

    <div class="title">
      <div class="tit_info">
        <span>资金流水:</span>
        近期连续3个月企业对公银行流水(或个人银行流水)(不接受Excel和隐去重要信息的流水，需要企业在开户行拉取或企业开户行网银账户截图，能清晰看到企业名称和往来明细)
      </div>
      <Upload-item @handleChange="(val) => importFile(val, 3)" :fileList="capitalFlowUrlList" @ondelete="(i) => ondelete(i, 3)"></Upload-item>
    </div>

    <div class="title">
      <div class="tit_info">
        <span>其他:</span>
        其他证明材料
      </div>
      <Upload-item @handleChange="(val) => importFile(val, 7)" :fileList="otherCertificateUrlList" @ondelete="(i) => ondelete(i, 7)"></Upload-item>
    </div>

    <div class="nextstep" v-if="source == 'addInfo' && !contractSignStatus" @click="handleSignContract">签署租赁框架协议</div>
    <div class="nextstep" v-else @click="supplemententerprise">提交补充资料</div>
    <div class="nextstep" v-if="source == 'UserOrder'" @click="supplemententerprise">提交补充资料</div>
    <div class="nextstep" v-if="source == 'User'" @click="onSubmitOrder">提交</div>
    <div class="nextstep" v-if="source == 'placeOrder'" @click="onSubmitted">提交审核立即租赁</div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ['source', 'enterpriseId', 'contractSignStatus'],
  data() {
    return {
      legalPersonDegreeCertificateUrlList: [],
      officeLeaseContractUrlList: [],
      premisesUrlList: [],
      paymentCertificateUrlList: [],
      socialInsurancePaymentCertificateUrlList: [],
      capitalFlowUrlList: [],
      otherCertificateUrlList: []
    }
  },
  watch: {},
  created() {
    this.getenterprisedetail()
  },
  methods: {
    importFile(val, type) {
      if (type == 1) {
        this.legalPersonDegreeCertificateUrlList.push(val)
      }
      if (type == 2) {
        this.socialInsurancePaymentCertificateUrlList.push(val)
      }
      if (type == 3) {
        this.capitalFlowUrlList.push(val)
      }
      if (type == 4) {
        this.premisesUrlList.push(val)
      }
      if (type == 5) {
        this.paymentCertificateUrlList.push(val)
      }
      if (type == 6) {
        this.officeLeaseContractUrlList.push(val)
      }
      if (type == 7) {
        this.otherCertificateUrlList.push(val)
      }
    },
    ondelete(i, type) {
      if (type == 1) {
        this.legalPersonDegreeCertificateUrlList.splice(i, 1)
      }
      if (type == 2) {
        this.socialInsurancePaymentCertificateUrlList.splice(i, 1)
      }
      if (type == 3) {
        this.capitalFlowUrlList.splice(i, 1)
      }
      if (type == 4) {
        this.premisesUrlList.splice(i, 1)
      }
      if (type == 5) {
        this.paymentCertificateUrlList.splice(i, 1)
      }
      if (type == 6) {
        this.officeLeaseContractUrlList.splice(i, 1)
      }
      if (type == 7) {
        this.otherCertificateUrlList.splice(i, 1)
      }
    },

    // 查看企业资料
    getenterprisedetail() {
      this.$axios
        .post('/zuwu-api/web/enterprise/details?enterpriseId=' + this.enterpriseId)
        .then((res) => {
          if (res.data.code == 200) {
            this.officeLeaseContractUrlList = res.data.data.officeLeaseContractUrlList
            this.legalPersonDegreeCertificateUrlList = res.data.data.legalPersonDegreeCertificateUrlList
            this.socialInsurancePaymentCertificateUrlList = res.data.data.socialInsurancePaymentCertificateUrlList
            this.capitalFlowUrlList = res.data.data.capitalFlowUrlList
            this.premisesUrlList = res.data.data.premisesUrlList
            this.paymentCertificateUrlList = res.data.data.paymentCertificateUrlList
            this.otherCertificateUrlList = res.data.data.otherCertificateUrlList
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    onSubmitted() {
      var currentCommodityDetail = JSON.parse(localStorage.getItem('currentCommodityDetail'))
      var data = {
        adChannel: currentCommodityDetail.adChannel,
        commodityNumber: currentCommodityDetail.commodityNumber,
        expectDeliveryDate: currentCommodityDetail.expectDeliveryDate,
        isEnterpriseOrder: currentCommodityDetail.isEnterpriseOrder,
        lat: '',
        leasePatternId: currentCommodityDetail.leasePatternId,
        lng: '',
        orderRemark: currentCommodityDetail.orderRemark,
        shareOpenId: currentCommodityDetail.shareOpenId,
        skuId: currentCommodityDetail.commoditySkuId,
        enterpriseId: this.enterpriseId
      }
      this.$axios
        .post('/zuwu-api/web/order/add/order/userLeasePattern', data)
        .then((res) => {
          if (res.data.code == 200) {
            this.supplemententerprise('jump')
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {
          this.$message.error('请重新下单')
        })
    },
    //补充资料
    supplemententerprise(msgType) {
      if (this.officeLeaseContractUrlList.length == 0) {
        this.$message.warning('办公房屋租赁合同资料不能为空！')
        return false
      }
      if (this.premisesUrlList.length == 0) {
        this.$message.warning('经营场地资料不能为空！')
        return false
      }
      var data = {
        enterpriseId: this.enterpriseId,
        premisesUrlList: this.premisesUrlList,
        capitalFlowUrlList: this.capitalFlowUrlList,
        officeLeaseContractUrlList: this.officeLeaseContractUrlList,
        otherCertificateUrlList: this.otherCertificateUrlList,
        paymentCertificateUrlList: this.paymentCertificateUrlList,
        socialInsurancePaymentCertificateUrlList: this.socialInsurancePaymentCertificateUrlList,
        userId: this.userId,
        orderId: this.orderId
      }
      this.$axios
        .post('/zuwu-api/web/enterprise/addSupplementInfo', data)
        .then((res) => {
          if (res.data.code == 200) {
            if (msgType !== 'noMsg') {
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.$emit('confirmSign')
              if (msgType == 'jump') {
                this.$router.push({ path: '/Submitted', query: { orderId: res.data.data.orderId } })
              }
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 添加补充资料
    onSubmitOrder() {
      var data = {
        premisesUrlList: this.premisesUrlList,
        capitalFlowUrlList: this.capitalFlowUrlList,
        officeLeaseContractUrlList: this.officeLeaseContractUrlList,
        otherCertificateUrlList: this.otherCertificateUrlList,
        paymentCertificateUrlList: this.paymentCertificateUrlList,
        socialInsurancePaymentCertificateUrlList: this.socialInsurancePaymentCertificateUrlList
      }
      this.$axios
        .post('/zuwu-api/web/enterprise/addSupplementInfo', data)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 签署框架协议
    async handleSignContract() {
      this.supplemententerprise('noMsg')
      let enterpriseId = this.enterpriseId
      this.btnLoading = true
      let { data } = await this.$axios.get('/zuwu-api/web/enterprise/getAgreementIsSignSuccess?enterpriseId=' + enterpriseId)
      if (data.code == 200 && !data.data) {
        setTimeout(() => {
          this.btnLoading = false
          this.$confirm('是否已完成框架协议的签署', '提示', {
            confirmButtonText: '已完成',
            cancelButtonText: '未完成'
          })
            .then(() => {
              this.$emit('confirmSign')
            })
            .catch(() => {
              this.$message({
                showClose: true,
                message: '请点击签署租赁框架协议进行签署！',
                type: 'warning'
              })
            })
        }, 2000)
        let { data: signedData } = await this.$axios.post('/zuwu-api/web/contract/getAgreementContractUrl', { enterpriseId })
        if (signedData.code == 200) {
          window.open(signedData.data.signUrl, '_blank')
        } else {
          this.$message({
            type: 'warning',
            message: signedData.msg
          })
        }
      } else {
        this.btnLoading = false
        this.$message({
          type: 'warning',
          message: '框架协议已签署，无需再次签署！'
        })
      }
    }
  }
}
</script>

<style scoped>
.materials {
  background: #fff;
  margin: 0 auto 30px;
  padding: 30px 50px 50px;
}
.materials .title {
  width: 100%;
  overflow: hidden;
  padding: 20px 10px;
}
.materials .title + .title {
  border-top: 1px dashed #999;
}
.materials .tit_info {
  font-size: 14px;
  color: #666;
  padding-bottom: 10px;
}
.materials .tit_info span {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin-right: 10px;
}

.nextstep {
  width: 300px;
  height: 50px;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  background: #1e95d4;
  font-size: 18px;
  line-height: 50px;
}
</style>
