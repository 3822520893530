var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"materials"},[_c('div',{staticClass:"title"},[_vm._m(0),_c('Upload-item',{attrs:{"fileList":_vm.officeLeaseContractUrlList},on:{"handleChange":(val) => _vm.importFile(val, 6),"ondelete":(i) => _vm.ondelete(i, 6)}})],1),_c('div',{staticClass:"title"},[_vm._m(1),_c('Upload-item',{attrs:{"fileList":_vm.premisesUrlList},on:{"handleChange":(val) => _vm.importFile(val, 4),"ondelete":(i) => _vm.ondelete(i, 4)}})],1),_c('div',{staticClass:"title"},[_vm._m(2),_c('Upload-item',{attrs:{"fileList":_vm.paymentCertificateUrlList},on:{"handleChange":(val) => _vm.importFile(val, 5),"ondelete":(i) => _vm.ondelete(i, 5)}})],1),_c('div',{staticClass:"title"},[_vm._m(3),_c('Upload-item',{attrs:{"fileList":_vm.socialInsurancePaymentCertificateUrlList},on:{"handleChange":(val) => _vm.importFile(val, 2),"ondelete":(i) => _vm.ondelete(i, 2)}})],1),_c('div',{staticClass:"title"},[_vm._m(4),_c('Upload-item',{attrs:{"fileList":_vm.capitalFlowUrlList},on:{"handleChange":(val) => _vm.importFile(val, 3),"ondelete":(i) => _vm.ondelete(i, 3)}})],1),_c('div',{staticClass:"title"},[_vm._m(5),_c('Upload-item',{attrs:{"fileList":_vm.otherCertificateUrlList},on:{"handleChange":(val) => _vm.importFile(val, 7),"ondelete":(i) => _vm.ondelete(i, 7)}})],1),(_vm.source == 'addInfo' && !_vm.contractSignStatus)?_c('div',{staticClass:"nextstep",on:{"click":_vm.handleSignContract}},[_vm._v("签署租赁框架协议")]):_c('div',{staticClass:"nextstep",on:{"click":_vm.supplemententerprise}},[_vm._v("提交补充资料")]),(_vm.source == 'UserOrder')?_c('div',{staticClass:"nextstep",on:{"click":_vm.supplemententerprise}},[_vm._v("提交补充资料")]):_vm._e(),(_vm.source == 'User')?_c('div',{staticClass:"nextstep",on:{"click":_vm.onSubmitOrder}},[_vm._v("提交")]):_vm._e(),(_vm.source == 'placeOrder')?_c('div',{staticClass:"nextstep",on:{"click":_vm.onSubmitted}},[_vm._v("提交审核立即租赁")]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit_info"},[_c('span',[_vm._v(" 办公房屋租赁合同: "),_c('i',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_vm._v(" 办公室的房屋租赁合同，可添加多张照片 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit_info"},[_c('span',[_vm._v(" 经营场地: "),_c('i',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_vm._v(" 场地办公照片(含门头照)，办公场地定位截图，办公场所视频，可添加多张照片 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit_info"},[_c('span',[_vm._v("缴费证明:")]),_vm._v(" 物业费、水费、电费、租金缴纳证明(缴费打款凭证、收据、发票，能清晰看到付款方和收款方) ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit_info"},[_c('span',[_vm._v("社保缴纳证明:")]),_vm._v(" 近期连续3个月员工(或个人)社保缴纳记录(不接受Excel版本，需要社保局拉取或者社保网站截图，能清晰看到企业名称、个人姓名和社保缴纳总人数)，简版和完整版都可 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit_info"},[_c('span',[_vm._v("资金流水:")]),_vm._v(" 近期连续3个月企业对公银行流水(或个人银行流水)(不接受Excel和隐去重要信息的流水，需要企业在开户行拉取或企业开户行网银账户截图，能清晰看到企业名称和往来明细) ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit_info"},[_c('span',[_vm._v("其他:")]),_vm._v(" 其他证明材料 ")])
}]

export { render, staticRenderFns }