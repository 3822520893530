<template>
  <div class="supplement">
    <div class="steptow" v-if="currentpage == 1">
      <div class="haved" v-if="enterpriselist.length > 0 && !detailsShow">
        <div class="title">已有企业信息</div>
        <div :class="['Businesscard', addBusinesscardval == index ? 'hover' : '']" v-for="(item, index) in enterpriselist" :key="index" @click="addBusinesscard(item, index)">
          <div class="arrows"></div>
          <div class="name">{{ item.enterpriseName }}</div>
          <span>统一社会信用代码：{{ item.socialCreditCode }}</span>
          <span>法人姓名：{{ item.legalPerson }}</span>
          <span>法人身份证号：{{ item.legalPersonIdCardNumber }}</span>
          <span>法人手机号：{{ item.legalPersonPhone }}</span>
          <span>法人联系地址：{{ item.address }}</span>
          <span>担保人：{{ item.guarantorType == 0 ? '法人' : item.guarantorType == 1 ? '代办人' : item.guarantorType }}</span>
          <span v-if="item.contractDownloadUrl" @click.stop="handleViewContract(item.contractDownloadUrl)">
            协议：
            <span style="display:inline;color:#0984e3">《租赁框架协议》</span>
            <el-tooltip class="item" effect="dark" content="点击下载" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </span>
          <el-button type="primary" v-else class="signContract" @click.stop="getSignedStatusAndUrl(item.id)" :loading="btnLoading">签署租赁框架协议</el-button>
        </div>
      </div>
      <div v-else-if="detailsShow">
        <div :class="['flex-item', Ocrhint ? 'danger' : '']" style="width:40%">
          <div class="title">
            营业执照
            <span>*</span>
          </div>
          <div class="imageBox">
            <Upload-img @handleChange="getbusinessLicenseOcr" :disabled="uploadDisabled" :imageUrl="enterpriseinfo.businessLicense" v-loading="loading"></Upload-img>
            <div class="id_msg"></div>
            <div class="reminder" v-if="Ocrhint" style="color: red; font-size: 12px">营业执照识别不通过</div>
          </div>
        </div>

        <div :class="['flex-item', Cardhint ? 'danger' : '']" style="width:60%">
          <div class="title">
            法人身份证正反面
            <span>*</span>
          </div>
          <div class="uploadBox">
            <div class="uploadItem">
              <Upload-img @handleChange="getidCardOcr" :disabled="uploadDisabled" :imageUrl="enterpriseinfo.legalPersonIdCardBack" v-loading="loading"></Upload-img>
              <div class="id_msg">人像</div>
            </div>
            <div class="uploadItem">
              <Upload-img @handleChange="getidCardBack" :disabled="uploadDisabled" :imageUrl="enterpriseinfo.legalPersonIdCardFront" v-loading="loading"></Upload-img>
              <div class="id_msg">国徽</div>
            </div>
            <div class="reminder" v-if="Cardhint" style="color: red; font-size: 12px">身份证识别不通过</div>
          </div>
        </div>

        <div class="clear"></div>
        <div class="nextstep">
          <el-button type="primary" @click="nextstep(2)" round>下一步</el-button>
        </div>
      </div>
    </div>

    <div class="stepthree" v-if="currentpage == 2">
      <el-form ref="formInfo" :model="form" :rules="rules" label-suffix="：" label-width="150px" inline class="formInfo" :disabled="uploadDisabled">
        <el-form-item label="企业名称" prop="enterpriseName">
          <el-input placeholder="企业名称" v-model="form.enterpriseName"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="socialCreditCode">
          <el-input placeholder="社会信用代码" v-model="form.socialCreditCode"></el-input>
        </el-form-item>
        <el-form-item label="企业地址" prop="address" class="specialItem">
          <el-input placeholder="地址" v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="法人姓名" prop="legalPersonIdCardName">
          <el-input placeholder="姓名" v-model="form.legalPersonIdCardName"></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号" prop="legalPersonIdCardNumber">
          <el-input placeholder="身份证号码" v-model="form.legalPersonIdCardNumber"></el-input>
        </el-form-item>
        <el-form-item label="法人手机号" prop="legalPersonPhone">
          <el-input placeholder="法人手机号" v-model="form.legalPersonPhone"></el-input>
        </el-form-item>
        <el-form-item label="法人联系地址" prop="legalPersonAddress">
          <el-input placeholder="法人联系地址" v-model="form.legalPersonAddress"></el-input>
        </el-form-item>
        <el-form-item label="约定账单日" prop="appointBillDate">
          <el-select placeholder="约定账单日" v-model="form.appointBillDate" style="width:100%">
            <el-option v-for="item in 28" :key="item" :label="item" :value="`${item}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="约定的支付周期" prop="appointPaymentCycle">
          <el-select placeholder="约定的支付周期" v-model="form.appointPaymentCycle" style="width:100%">
            <el-option v-for="item in paymentList" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="nextstep">
        <el-button @click="nextstep(1)" round>上一步</el-button>
        <template v-if="!contractSignStatus">
          <el-button type="primary" @click="handleSubmitInfo('addInfo')" round :loading="btnLoading">材料齐全，继续补充资料</el-button>
          <el-button type="primary" @click="handleSubmitInfo('sign')" round :loading="btnLoading">材料后补，直接签署框架协议</el-button>
        </template>
        <template v-else>
          <el-button type="primary" @click="handleSubmitInfo('view')" round :loading="btnLoading">确定</el-button>
        </template>
      </div>
    </div>

    <div class="stepthree" v-if="currentpage == 3">
      <materials :enterpriseId="enterpriseinfo.id" source="addInfo" :contractSignStatus="false" @confirmSign="handleRefresh"></materials>
    </div>
  </div>
</template>
<script>
import materials from './materials.vue'
import {addOrderUserLeasePattern} from "@/api/order";
import {getEnterpriseInfoList} from "@/api/userInfoPort";
export default {
  components: { materials },
  props: ['source'],
  data() {
    const validateName = (rule, value, callback) => {
      let { legalPerson } = this.enterpriseinfo
      if (value === '') {
        callback(new Error('请输入法人姓名！'))
      } else if (value !== legalPerson) {
        callback(new Error('输入的法人姓名与营业执照不一致！'))
      } else {
        callback()
      }
    }
    return {
      uploadDisabled: false,
      detailsShow: false,
      progress: 0,
      addenterpriseval: 0,
      currentpage: 1,
      guarantorType: 0,
      enterpriseinfo: {},
      enterpriselist: [],
      addBusinesscardval: null,
      form: {
        appointBillDate: '',
        appointPaymentCycle: '',
        legalPersonIdCardName: '',
        legalPersonIdCardNumber: '',
        enterpriseEmail: '无',
        legalPersonPhone: '',
        legalPersonEmail: '无',
        enterpriseName: '',
        address: '',
        legalPerson: '',
        socialCreditCode: '',
        legalPersonAddress: ''
      },
      Ocrhint: false,
      Cardhint: false,

      loading: false,
      paymentList: [
        {
          label: '月付',
          value: '0'
        },
        {
          label: '季付',
          value: '1'
        },
        {
          label: '半年付',
          value: '2'
        },
        {
          label: '年付',
          value: '3'
        }
      ],
      rules: {
        enterpriseName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        socialCreditCode: [{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
        address: [{ required: true, message: '请输入企业地址', trigger: 'blur' }],
        legalPersonIdCardName: [{ required: true, validator: validateName, trigger: 'blur' }],
        legalPersonIdCardNumber: [{ required: true, message: '请输入法人身份证号', trigger: 'blur' }],
        legalPersonPhone: [{ required: true, message: '请输入法人手机号', trigger: 'blur' }],
        legalPersonAddress: [{ required: true, message: '请输入法人联系地址', trigger: 'blur' }],
        appointBillDate: [{ required: true, message: '请选择约定的账单日', trigger: 'blur' }],
        appointPaymentCycle: [{ required: true, message: '请输入约定的支付周期', trigger: 'blur' }]
      },
      // 合同签署状态
      contractSignStatus: false,
      // 控制按钮是否被点击状态
      btnLoading: false
    }
  },
  watch: {},
  created() {
    this.getEnterpriseInfoList()
  },
  methods: {
    // 查看企业信息
    getEnterpriseInfoList() {
      getEnterpriseInfoList().then((res) => {
          if (res.data.code == 200) {
            var data = res.data.data
            this.enterpriselist = data
            if (this.enterpriselist.length == 0) {
              this.detailsShow = true
            }
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    async addBusinesscard(inf, val) {
      this.enterpriseinfo = inf
      if (this.source == 'placeOrder') {
        let { data } = await this.$axios.get('/zuwu-api/web/enterpriseInfo/getAgreementIsSignSuccess?enterpriseId=' + this.enterpriseinfo.id)
        if (data.code == 200 && data.data) {
          this.onSubmitted()
        } else {
          this.$message({
            showClose: true,
            message: '请点击签署租赁框架协议进行签署！',
            type: 'warning'
          })
        }
        return
      }
      this.uploadDisabled = true
      this.detailsShow = true
      this.addBusinesscardval = val
      this.addenterpriseval = 0
      this.contractSignStatus = inf.contractDownloadUrl ? true : false
      this.guarantorType = inf.guarantorType
      if (this.source == 'User') {
        for (var i in this.form) {
          this.form[i] = inf[i]
        }
        this.handleChangeBar()
      }
    },

    async onSubmitted() {
      let { data: signedStatus } = await this.$axios.get('/zuwu-api/web/enterpriseInfo/getAgreementIsSignSuccess?enterpriseId=' + this.enterpriseinfo.id)
      if (!signedStatus.data) {
        this.$alert('租赁框架协议尚未签署，请至个人中心添加企业信息并签署租赁框架协议!', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      var currentCommodityDetail = JSON.parse(localStorage.getItem('currentCommodityDetail'))
      var data = {
        adChannel: currentCommodityDetail.adChannel,
        commodityNumber: currentCommodityDetail.commodityNumber,
        expectDeliveryDate: currentCommodityDetail.expectDeliveryDate,
        isEnterpriseOrder: currentCommodityDetail.isEnterpriseOrder,
        lat: '',
        leasePatternId: currentCommodityDetail.leasePatternId,
        lng: '',
        orderRemark: currentCommodityDetail.orderRemark,
        shareOpenId: currentCommodityDetail.shareOpenId,
        skuId: currentCommodityDetail.commoditySkuId,
        enterpriseId: this.enterpriseinfo.id
      }
      addOrderUserLeasePattern(data).then((res) => {
          console.log(res)
          if (res.data.code == 200) {
            this.$router.push({ path: '/Submitted', query: { orderId: res.data.data.orderId, isEnterpriseOrder: currentCommodityDetail.isEnterpriseOrder } })
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {
          // this.$message.error('请重新下单')
        })
    },

    DWauthorization() {
      window.open('https://file.juuzu.com/%E4%BB%A3%E5%8A%9E%E5%A7%94%E6%89%98%E6%8E%88%E6%9D%83%E4%B9%A6.docx')
    },
    nextstep(val) {
      this.currentpage = val
      // dier
      if (this.currentpage == 1) {
        this.handleChangeBar()
      }
      if (val == 0 && this.enterpriselist.length > 0) {
        this.addBusinesscard(this.enterpriselist[this.addBusinesscardval], this.addBusinesscardval)
      }
    },

    handleSubmitInfo(type) {
      this.$refs['formInfo'].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          let data = Object.assign(this.enterpriseinfo, this.form, { guarantorType: this.guarantorType, lesseeType: 0, legalPerson: this.form.legalPersonIdCardName })
          this.$axios
            .post('/zuwu-api/web/enterprise/addEnterpriseInfo', data)
            .then((res) => {
              if (res.data.code == 200) {
                if (type == 'addInfo') {
                  this.enterpriseinfo = {
                    ...this.enterpriseinfo,
                    id: res.data.data
                  }
                  this.currentpage = 3
                } else if (type == 'sign') {
                  this.getSignedStatusAndUrl(res.data.data)
                } else if (type == 'view') {
                  this.$emit('materials', { msg: '', isShow: false })
                } else if (this.source == 'User') {
                  this.$emit('materials', { msg: '绑定企业成功', isShow: true })
                }
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: 'warning'
                })
              }
              setTimeout(() => {
                this.btnLoading = false
              }, 1000)
            })
            .catch((err) => {
              this.btnLoading = false
              return Promise.reject(err)
            })
        }
      })
    },
    getbusinessLicenseOcr(url) {
      this.loading = true
      var formData = new FormData()
      formData.append('fileUrl', url)
      this.$axios({
        url: '/zuwu-api/web/enterprise/businessLicenseOcr',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then((res) => {
          this.loading = false
          if (res.data.code == 200) {
            this.Ocrhint = false
            this.enterpriseinfo = res.data.data
            this.enterpriseinfo.businessLicense = url
            this.form.enterpriseName = res.data.data.enterpriseName
            this.form.legalPerson = res.data.data.legalPerson
            this.form.socialCreditCode = res.data.data.socialCreditCode
            this.form.address = res.data.data.address
            this.$message({
              message: '上传成功',
              type: 'success'
            })

            this.handleChangeBar()
          } else {
            this.$message({
              message: res.data.msg,
              type: 'warning'
            })
            this.enterpriseinfo.businessLicense = ''
            this.Ocrhint = true
          }
        })
        .catch((err) => {
          this.$message.error(err.data.msg)
        })
    },
    getidCardOcr(url) {
      this.loading = true
      var formData = new FormData()
      formData.append('fileUrl', url)
      this.$axios({
        url: '/zuwu-api/web/enterprise/idCardOcr',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then((res) => {
          this.loading = false
          if (res.data.code == 200) {
            this.Cardhint = false
            this.form.legalPersonIdCardName = res.data.data.name
            this.form.legalPersonIdCardNumber = res.data.data.idCardNumber
            this.form.legalPersonAddress = res.data.data.address

            this.enterpriseinfo.legalPersonIdCardBack = url
            this.$message({
              message: '上传成功',
              type: 'success'
            })
            this.handleChangeBar()
          } else {
            this.$message({
              message: res.data.msg,
              type: 'warning'
            })
            this.enterpriseinfo.legalPersonIdCardBack = ''
            this.Cardhint = true
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },

    getidCardBack(url) {
      this.enterpriseinfo.legalPersonIdCardFront = url
      this.handleChangeBar()
    },

    getletterOfAuthorization(url) {
      this.enterpriseinfo.letterOfAuthorization = url
      this.handleChangeBar()
    },
    handleChangeBar() {
      let { businessLicense, legalPersonIdCardBack, legalPersonIdCardFront, letterOfAuthorization } = this.enterpriseinfo
      let curData = {
        businessLicense,
        legalPersonIdCardBack,
        legalPersonIdCardFront,
        letterOfAuthorization
      }
      let list = Object.values(curData)
      let len = list.length
      switch (len) {
        case 0:
          this.progress = 0
          break
        case 1:
          this.progres = 12
          break
        case 2:
          this.progress = 24
          break
        case 3:
          this.progress = 36
          break
        case 4:
          this.progress = 48
          break

        default:
          break
      }
    },

    async getSignedStatusAndUrl(enterpriseId) {
      this.btnLoading = true
      let { data } = await this.$axios.get('/zuwu-api/web/enterpriseInfo/getAgreementIsSignSuccess?enterpriseId=' + enterpriseId)
      if (data.code == 200 && !data.data) {
        setTimeout(() => {
          this.btnLoading = false
          this.$confirm('是否已完成框架协议的签署', '提示', {
            confirmButtonText: '已完成',
            cancelButtonText: '未完成'
          })
            .then(() => {
              this.handleRefresh()
            })
            .catch(() => {
              this.$message({
                showClose: true,
                message: '请点击签署租赁框架协议进行签署！',
                type: 'warning'
              })
            })
        }, 2000)
        let { data: signedData } = await this.$axios.post('/zuwu-api/web/contract/getAgreementContractUrl', { enterpriseId })
        if (signedData.code == 200) {
          window.open(signedData.data.signUrl, '_blank')
        } else {
          this.$message({
            type: 'warning',
            message: signedData.msg
          })
        }
      } else {
        this.btnLoading = false
        this.$message({
          type: 'warning',
          message: '框架协议已签署，无需再次签署！'
        })
      }
    },

    handleViewContract(contractDownloadUrl) {
      window.open(contractDownloadUrl)
    },

    handleRefresh() {
      this.currentpage = 1
      this.detailsShow = false
      this.getEnterpriseInfoList()
    }
  }
}
</script>

<style scoped>
.supplement {
  background: #fff;
  margin: 0 auto 30px;
  padding: 10px 0 0;
}
.haved {
  padding: 0 25px 0;
}
.haved .title {
  font-size: 18px;
  line-height: 32px;
  text-indent: 2rem;
  margin-bottom: 10px;
}
.steptow .Businesscard {
  width: 45%;
  margin: 0 0.5% 10px;
  display: inline-block;
  border: 1px solid #1e95d4;
  text-align: left;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  min-height: 260px;
  vertical-align: top;
  cursor: pointer;
  border-radius: 5px;
  background: linear-gradient(360deg, #d2f4ff 0%, #fdfdfd 100%);
}

.steptow .Businesscard.hover {
  background: linear-gradient(360deg, #1e95d4 0%, #6ebadd 100%);
  color: #fff;
}
.hover {
  background: linear-gradient(360deg, #1e95d4 0%, #6ebadd 100%);
  color: #fff;
}
.steptow .Businesscard .name {
  margin: 5px auto;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}
.steptow .Businesscard span {
  display: block;
  margin-top: 2px;
}
.steptow {
  height: 430px;
}
.clear {
  font-size: 0;
  line-height: 0;
  clear: both;
  overflow: hidden;
  zoom: 1;
}
.flex-item {
  float: left;
  padding-bottom: 20px;
}
.flex-item + .flex-item {
  border-left: 1px dashed #999;
  box-sizing: border-box;
}
.flex-item .title {
  font-size: 20px;
  text-align: center;
  margin: 20px auto 30px;
}
.flex-item .title span {
  color: red;
}
.flex-item .imageBox {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 80%;
}
.flex-item .uploadBox {
  width: 100%;
  text-align: center;
}
.flex-item .uploadItem {
  width: 46%;
  margin: 0 2%;
  float: left;
}
.flex-item .id_msg {
  line-height: 24px;
  height: 24px;
}

.stepthree {
  margin: 0 20px;
}
.stepthree .title_name {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 20px auto 20px;
  overflow: hidden;
}
.stepthree .title {
  width: 40%;
  display: inline-block;
  margin: 0 5%;
  overflow: hidden;
  height: 80px;
}
.stepthree .title span {
  display: block;
  font-size: 16px;
  padding-bottom: 5px;
  padding-top: 10px;
}
.guarantee {
  text-align: center;
}
.guarantee /deep/ .el-radio {
  margin: 0 20px;
  color: #000;
}
.guarantee /deep/ .el-radio__label {
  font-size: 16px;
}

.nextstep {
  text-align: center;
  font-size: 0;
  margin: 30px auto;
  line-height: 60px;
  font-size: 24px;
  width: 100%;
  overflow: hidden;
}
.nextstep /deep/ .el-button {
  font-size: 20px;
}
.nextstep /deep/ .el-button--primary {
  background: #1e95d4;
}
.danger {
  box-shadow: 0 0 5px red;
  border-radius: 10px;
}
.nextstep /deep/ .el-button.is-round {
  border-radius: 5px;
}

.formInfo /deep/ .el-form-item {
  width: calc(50% - 10px);
  position: relative;
}
.formInfo /deep/ .el-form-item__content {
  width: calc(100% - 160px);
}

.formInfo /deep/ .specialItem {
  width: calc(100% - 10px);
}
.signContract {
  width: 100%;
  margin-top: 6px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}
</style>
